import React, { useRef, useState } from 'react';
import {
  BrowserRouter as Router,
  Link,
  Route
} from 'react-router-dom';

import { Document, Page } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import './Menu.css';

import latestMenuGermanPDF from '../files/latest_de.pdf'
import latestMenuEnglishPDF from '../files/latest_en.pdf'

const Menu = ({ lang }) => {

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const pdfDoc = useRef();

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);
  const onDocumentLoadError = err => console.error(err);

  const prevClickHandler = ev => {
    ev.preventDefault();
    setPageNumber(pageNumber > 1 ? pageNumber - 1 : pageNumber)
  }

  const nextClickHandler = ev => {
    ev.preventDefault();
    setPageNumber(pageNumber < numPages ? pageNumber + 1 : pageNumber);
  }

  return (
    <>
    {/* <Route path={ ["/karte", "/menu" ] }> */}
      <div id="Menu">
        <div style={{
          marginBottom: '0.5em',
        }}>
          <Link to="/" style={{
            margin: '1em',
            padding: '0.5em 2em',
            textDecoration: 'none',
            backgroundColor: 'white',
            borderRadius: '1em'
          }}>{ lang !== 'de' ? 'close' : 'Schließen' }
          </Link>
        </div>
        <Document
          id="MenuPDF"
          ref={pdfDoc}
          file={lang !== 'en' ? latestMenuGermanPDF : latestMenuEnglishPDF}
          options={{ workerSrc: "/pdf.worker.js" }}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
        >
          <Page pageNumber={pageNumber} scale={0.9} />
        </Document>
        <div className="navigation">
          <a href="" className={pageNumber < 2 ? 'inactive' : null} onClick={prevClickHandler}>❮</a>
        { lang === 'en' ? 'page' : 'Seite '} {pageNumber} { lang === 'en' ? 'of' : 'von'} {numPages}
          <a href="" className={pageNumber > numPages - 1 ? 'inactive' : null} onClick={nextClickHandler}>❯</a>
        </div>
      </div>
      </>
    // </Route>
  )
}

export default Menu
