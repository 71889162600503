import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

import './App.css';

import Main from './pages/Main';
import Menu from './pages/Menu';

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/karte" >
            <Menu lang="de" />
          </Route>
          <Route path="/menu" >
            <Menu lang="en" />
          </Route>
          <Route path="/">
            <Main />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
