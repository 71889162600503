import React from 'react';
import { Link } from 'react-router-dom';

import './Main.css';

import { ReactComponent as TapeteLogo } from '../images/logos/TapeteLogo.svg';

const Main = () => {
  return (
    <div className="info">
      <TapeteLogo />
      <div style={{
        borderTop: '0.5px solid white',
        paddingTop: '1em'
      }}>
        <p><a href="https://www.google.at/maps/place/Tapete+Bar/@48.1869603,16.3579915,17z/data=!3m1!4b1!4m5!3m4!1s0x476da82bf1f26bff:0x8484e104cb070d10!8m2!3d48.1869606!4d16.3601808">Tapete Bar // Zentagasse 14 // 1050 Wien</a></p>
        <p><a href="mailto:reservierungen@tapete.bar">reservierungen@tapete.bar</a> // <a href="tel:+4319664346">+43 1 966 43 46</a></p>
        <p>Mo-Do 18-0 Uhr, Fr+Sa 18-2 Uhr</p>
        <br></br>
        <p><Link to="/karte">Getränkekarte</Link> // <Link to="/menu">Menu (EN)</Link></p>
        <br></br>
        <p>find us on:</p>
        <p><a href="https://www.facebook.com/tapete.bar" target="_blank">Facebook</a> // <a href="https://www.instagram.com/tapete.bar" target="_blank">Instagram</a></p>
      </div>
    </div>
  )
}

export default Main
